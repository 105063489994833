.notification{
  margin: 0 auto;
  position: fixed;
  bottom: 6%;
  left: 0;
  right: 0;

  .cds--inline-notification__close-button .cds--inline-notification__close-icon{
    fill : #000;
  }
}
