@use '@carbon/react/scss/spacing' as *;

.pt3rem{
    padding-top: 3rem;
}

.mt16 {
    margin-top: 16px;
}

.banner {
    padding-top: $spacing-05;
    padding-bottom: $spacing-07;
}

.cds--toolbar-content{
    svg{
        position: absolute;
        right: 1rem;
    }

    .cds--toolbar-search-container-active {
        .cds--search-magnifier-icon{
            display: none;
        }
    }

    .cds--search-input{
        padding-left: 1rem !important;
    }
}

.cds--data-table-container {
    padding-top: 0;
}

.cds--data-table{
    tr{
        .editIcon {
            visibility: hidden;
        }
        &:hover .editIcon {
            visibility:visible;
            cursor: pointer;
        }
    }
    td{
        max-width: 280px;
        word-wrap: break-word;
    }
}

.searchButton{
    padding: 24px;
    margin-right: 2px;
}

.filterRow {
    margin: 0;
    border: 1px solid #ECECEC;
}

.filterTitleText {
    display: flex;
    align-items: center;
    margin-right: 50px;
    svg {
        margin-right: 5px;
    }
}

.multiSelectFilter {
    width: 100%;
}

.noPaddingRight {
    padding-right: 0;
}

.tableCountValue {
    color: #0f62fe;
    cursor: pointer;
}

.popOverBox {
    padding: 10px;
    width: 12rem;
    min-height: 8rem;
}

.cds--popover{
    z-index: 1;
}

.cursor-pointer {
    cursor: pointer;
}

