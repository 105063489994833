.sidePanel {
    width: 400px;
    position: fixed;
    top: 48px;
    right: 0px;
    height: calc(100vh - 3rem);
    background-color: white;
    box-shadow: 0 8px 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;

    .cds--grid {
        height: 100%;
    }
}

.panelTitle { 
    margin-top: 40px;
    margin-bottom: 20px;
}

.sidePanelContent {
    margin-bottom: 60px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 3rem - 175px);

    .contentSection {
        max-height: 95%;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: none;
    }
}

.panelCancelIcon { 
    cursor: pointer;
    float: right;
}

.textAreaCount {
    display: flex;
    justify-content: end;
}

.sidePanelTextArea .cds--label {
    width: 100%;
}

.sidePanelButtons {
    position: absolute;
    height: 40px;
    bottom: 65px;
    width: 100%;
    padding-right: 50px;
    padding-left: 15px;
    .cds--btn {
        width: 100%;
    }
}

.alignSecondaryButton {
    padding-left: 0px;
}

.alignPrimaryButton {
    padding-right: 0px;
}

.addKeywordButton {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
    .addFilledIcon {
        margin-right: 5px;
    }
}

.saveKeywordbutton {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: end;
    
    &.enabled {
        color: #0F61FD;
        cursor: pointer;
    }

    &.disabled {
        color: #8d8d8d;
        cursor: not-allowed;
    }
}

.addkeywordSecondaryButton {
    font-size: 14px;
    color: #0F61FD;
    display: flex;
    align-items: center;
    justify-content: start;
}

.displayNone {
    display: none;
}