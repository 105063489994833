@use '@carbon/react/scss/config' with (
    $css--default-type: true,
    $css--reset: true,
    $use-flexbox-grid: true
);
@use '@carbon/react';
@use '@carbon/react/scss/reset';
@use '@carbon/react/scss/grid';
@use '@carbon/react/scss/components';


body {
  margin: 0;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color : #ffffff;
  color: #545454;
}
