/*Import all scss files here*/

@import './pages/ElementList/ElementList.scss';
@import './components//Header/Header.scss';
@import './components/AppNotification/AppNotification.scss';
@import './components/Modals/Modals.scss';
@import './components/SidePanel/SidePanel.scss';

.cds--grid{
  padding-bottom: 2rem;
}

