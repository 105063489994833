.marginBottom1rem{
  margin-bottom: 1rem;
}

.cds--modal-content{
  font-size: 12px ;

  p{
    font-size: 12px ;
  }
}